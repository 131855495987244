import React from "react"

import Layout from "../components/layout/layout.tsx"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <br/>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>This page doesn&#39;t exist... </p>
  </Layout>
)

export default NotFoundPage
